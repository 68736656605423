/* variables */
:root {
  --primary: #198754;
  --secondary: #0d6efd;
  --success: #198754;
  --danger: #dc3545;
  --warning: #ffc107;
  --info: #0dcaf0;
  --light: #f8f9fa;
}

th {
  background-color: var(--light) !important;
  border-color: rgba(0, 0, 0, 0.175);
  font-weight: 500 !important;
  color: #000 !important;
}

.rounded-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary);
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
